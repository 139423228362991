<template>
    <b-card>
        <div class="row">
            <div class="col-md-4 mb-1">
                <label class="form-label">Tarih Aralığı</label>
                <v-date-picker v-model="date" range placeholder="Tarih Aralığı"></v-date-picker>        
            </div>
            <div class="col-md-4 mb-1">
                <label class="form-label">Taşımacı Cari</label>
                <v-select-list v-model="cari" :options="cariList" label="CARI_ISIM" v-on:search="getCariList" placeholder="Taşımacı Cari" :loading="cariLoading"></v-select-list>
            </div>
            <div class="col-md-4 mb-1">
                <label class="form-label">Bitiş Firma</label>
                <v-select-list v-model="targetCari" :options="targetCariList" label="CARI_ISIM" v-on:search="getTargetCariList" placeholder="Bitiş Firma" :loading="targetCariLoading"></v-select-list>
            </div>
            <div class="col-md-4 mb-1">
                <label class="form-label">Stok</label>
                <v-select-list v-model="stok" :options="stoks" label="Name"  placeholder="Stok" :loading="stokLoading"></v-select-list>
            </div>
            <div class="col-md-4 mb-1">
                <label class="form-label">Şube</label>
                <v-select-list v-model="sube" :options="subeList" label="BranchName" placeholder="Şube"></v-select-list>
            </div>
            <div class="col-md-4 mb-1">
                <label class="form-label">Bölge</label>
                <v-select-list v-model="transferRegion" :options="transferRegionList" label="RegionName" placeholder="Bölge"></v-select-list>
            </div>
          
        </div>
    
        <div class="row justify-content-end">
            <div class="col-auto mb-1 mb-md-0">
                <v-executer :loading="filterLoading" :action="filter">Filtrele</v-executer>
            </div>
        </div>
        
        <div class="row mt-2">
            <div class="col-12">
                <h2>Seferler</h2>
                <div class="table-responsive">
                    <table class="table table-sm align-middle">
                        <thead>
                            <tr>
                                <th>Sıra</th>
                                <th v-if="(createErrors || []).length">Bilgi</th>
                                <th class="text-nowrap">İrsaliye No</th>
                                <th class="text-nowrap">Tarih</th>
                                <th class="text-nowrap">Başlangıç Firma</th>
                                <th class="text-nowrap">Başlangıç Bölge</th>
                                <th class="text-nowrap">Bitiş Firma</th>
                                <th class="text-nowrap">Bitiş Bölge</th>
                                <th class="text-nowrap">Taşınan Ürün Adı</th>
                                <th class="text-nowrap">Taşınan Ürün Miktarı</th>
                                <th class="text-nowrap">Araç Tipi</th>
                                <th class="text-nowrap">Taşıma Durumu</th>
                            </tr>
                        </thead>
                        <tbody>
                            <template v-for="(data,index) in successSefers">
                                <tr :key="index">
                                    <td class="text-nowrap">{{ index+1 }}</td>
                                    <td class="text-nowrap" v-if="(createErrors || []).length">
                                        <span class="material-icons-outlined d-block text-danger"  v-b-tooltip.noninteractive.hover.bottom :title="getError(data)" v-if="getError(data)">error_outline</span>
                                        <span class="material-icons-outlined text-success" v-else>check_circle</span>
                                    </td>
                                    <td class="text-nowrap">{{ data.IrsaliyeNo || '-' }}</td>
                                    <td class="text-nowrap">{{ data.Gun || '-' }}</td>
                                    <td class="text-nowrap" data-original-title="999" data-container="body" data-toggle="tooltip" data-placement="bottom" :title="data.BaslangicFirmaKodu">
                                        {{ data.BaslangicFirmaAdi || '-' }} 
                                    </td>
                                    <td class="text-nowrap">{{ data.StartRegionName || '-' }} </td>
                                    
                                    <td class="text-nowrap" data-original-title="999" data-container="body" data-toggle="tooltip" data-placement="bottom" :title="data.BitisFirmaKodu" >
                                        {{ data.BitisFirmaAdi || '-' }}
                                    </td>
                                    <td class="text-nowrap">{{ data.EndRegionName || '-' }}</td>
                                    <td class="text-nowrap">{{ data.TasinanUrunAdi || '-' }}</td>
                                    <td class="text-nowrap">{{ data.TasinanUrunMiktariStr || '-' }}</td>
                                    <td class="text-nowrap">{{ data.AracTipiAdi || '-' }}</td>
                                    <td class="text-nowrap">{{ data.TasimaDurumu || '-' }}</td>
                                </tr>
                            </template>
                            <tr v-if="(successSefers || []).length == 0">
                                <td colspan="100" class="text-center">Henüz bir veri bulunmamaktadır.</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="row justify-content-end">
            <div class="col-auto mb-1 mb-md-0" v-if="(successSefers.length  || [] ) >0">
                <v-executer :loading="createIrsaliyeLoading" :action="createIrsaliyeGroup">Referans Grubu Oluştur</v-executer>
            </div>
        </div>
        <div v-if="(errorSefers.length || [] ) > 0" class="row mt-2">
            <div class="col-12">
                <h2>Eksik Bilgisi Olan Seferler</h2>
                <div class="table-responsive">
                    <table class="table table-sm align-middle">
                        <thead>
                            <tr>
                                <th>Sıra</th>
                                <th class="text-nowrap">İrsaliye No</th>
                                <th class="text-nowrap">Tarih</th>
                                <th class="text-nowrap">Başlangıç Firma</th>
                                <th class="text-nowrap">Başlangıç Bölge</th>
                                <th class="text-nowrap">Bitiş Firma</th>
                                <th class="text-nowrap">Bitiş Bölge</th>
                                <th class="text-nowrap">Taşınan Ürün Adı</th>
                                <th class="text-nowrap">Taşınan Ürün Miktarı</th>
                                <th class="text-nowrap">Araç Tipi</th>
                                <th class="text-nowrap">Taşıma Durumu</th>
                            </tr>
                        </thead>
                        <tbody>
                            <template v-for="(data,index) in errorSefers">
                                <tr :key="index">
                                    <td>{{ index+1 }}</td>
                                    <td class="text-nowrap">{{ data.IrsaliyeNo || '-' }}</td>
                                    <td class="text-nowrap">{{ data.Gun || '-' }}</td>
                                    <td class="text-nowrap" data-original-title="999" data-container="body" data-toggle="tooltip" data-placement="bottom" :title="data.BaslangicFirmaKodu" >
                                        {{ data.BaslangicFirmaAdi || '-' }}
                                    </td>
                                    <td class="text-nowrap">{{ data.StartRegionName || '-' }} </td>
                                    <td class="text-nowrap" data-original-title="999" data-container="body" data-toggle="tooltip" data-placement="bottom" :title="data.BitisFirmaKodu" >
                                        {{ data.BitisFirmaAdi || '-' }}
                                    </td>
                                    <td class="text-nowrap">{{ data.EndRegionName || '-' }}</td>
                                    <td class="text-nowrap">{{ data.TasinanUrunAdi || '-' }}</td>
                                    <td class="text-nowrap">{{ data.TasinanUrunMiktariStr || '-' }}</td>
                                    <td class="text-nowrap">{{ data.AracTipiAdi || '-' }}</td>
                                    <td class="text-nowrap">{{ data.TasimaDurumu || '-' }}</td>
                                </tr>
                            </template>
                            <tr v-if="(errorSefers || []).length == 0">
                                <td colspan="100" class="text-center">Henüz bir veri bulunmamaktadır.</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </b-card>
</template>

<script>
import { BBadge, BCard,BFormCheckbox, VBTooltip } from 'bootstrap-vue'
import { NetsisApi } from '@/api/netsis'
import { TransferRegionApi } from '@/api/transfer-region'
import { MaterialApi } from '@/api/material'
import { IrsaliyeOperationsApi } from '@/api/irsaliye'
import moment from 'moment'
import VStatsBox from '@/components/VStatsBox.vue'
export default {
    components:{
        BCard,
        BBadge,
        VStatsBox,
        BFormCheckbox
    },
    directives:{
        "b-tooltip":VBTooltip
    },
    data(){
        return{
            queryData:{
                StartDate: moment().startOf('month').format('YYYY-MM-DD'),
                EndDate:moment().endOf('month').format("YYYY-MM-DD"),
                CariCode:null,
                TargetCariCode:null,
                StockCode:null,
                BranchCode:null,
                RegionCode:null
            },
            //Query data değişebilir ancak filtrelenmeyebilir. Filtrelenmediği zaman querydata üzerinden ref. kod oluşursa hatalı bir çalışma olur.
            filteredData:{
                StartDate: moment().startOf('month').format('YYYY-MM-DD'),
                EndDate:moment().endOf('month').format("YYYY-MM-DD"),
                CariCode:null,
                TargetCariCode:null,
                StockCode:null,
                BranchCode:null
            },
            cariList:[],
            targetCariList:[],
            cariLoading:false,
            targetCariLoading:false,
            errorSefers:[],
            successSefers:[],
            stoks:[],
            filterLoading:false,
            stokLoading:false,
            createIrsaliyeLoading:false,
            subeList:[],
            subeLoading:false,
            transferRegionList:[],
            transferRegionLoading:false,
            createErrors:[]
        }
    },
    mounted(){
        this.getSubeList()
        this.getRegionList()
        this.getStoks()
    },
    methods:{
        getRegionList(){
            this.transferRegionLoading = true
            TransferRegionApi.List().then(res =>{
                this.transferRegionList = res.data.Data
                this.transferRegionLoading =false
            }).catch(err => {
                this.transferRegionLoading =false
            })
        },
        getSubeList(){
            this.subeLoading = true
            NetsisApi.GetSubeList().then(res =>{
                if(res.data.Success){
                    this.subeList = res.data.Data
                }
                this.subeLoading = false
            }).catch(err=>{
                this.subeLoading = false
            })
        },
        filter(){
            this.createErrors=null
            this.filteredData.StartDate=this.queryData.StartDate
            this.filteredData.EndDate=this.queryData.EndDate,
            this.filteredData.CariCode=this.queryData.CariCode,
            this.filteredData.TargetCariCode=this.queryData.TargetCariCode,
            this.filteredData.StockCode=this.queryData.StockCode,
            this.filteredData.BranchCode=this.queryData.BranchCode
            this.filteredData.RegionCode = this.queryData.RegionCode
            this.filterLoading = true
            NetsisApi.GetSeferList(this.filteredData).then(res => {
                if(!res.data.Success){
                    this.$result(res)
                }else{
                    this.errorSefers = res.data.Data.ErrorSefers
                    this.successSefers = res.data.Data.SuccessSefers
                }
                this.filterLoading = false
            }).catch(err=> {
                console.log(err)
                this.filterLoading = false
            })
        },
        getStoks(){
            this.stokLoading = true
            MaterialApi.List().then(res => {
                this.stoks = res.data.Data
                this.stokLoading = false
            }).catch(err=> {
                console.log(err)
                this.stokLoading = false
            })
        },
        getCariList(searchKey){
            if(searchKey){
                this.cariLoading = true
                NetsisApi.CariList(searchKey).then(res => {
                    this.cariList = res.data.Data
                    this.cariLoading = false
                }).catch(err=> {
                    console.log(err)
                    this.cariLoading = false
                })
            }
        },
        getTargetCariList(searchKey){
            if(searchKey){
                this.targetCariLoading = true
                NetsisApi.CariList(searchKey).then(res => {
                    this.targetCariList = res.data.Data
                    this.targetCariLoading = false
                }).catch(err=> {
                    console.log(err)
                    this.targetCariLoading = false
                })
            }
        },
        createIrsaliyeGroup(){
            this.createIrsaliyeLoading = true
            IrsaliyeOperationsApi.Create({
                StartDate:this.queryData.StartDate,
                EndDate:this.queryData.EndDate,
                Sefers: this.successSefers
            }).then(res =>{
                this.$result(res)
                this.createIrsaliyeLoading = false
                if(res.data.Success){
                    this.$router.push({name:'waybill-operations'})
                }else{
                    this.createErrors = res.data.Data
                }
            }).catch(err => {
                console.log(err)
                this.createIrsaliyeLoading = false
            })
        },
        getError(item){
            return this.createErrors?.find(e=>e.IrsaliyeNo == item.IrsaliyeNo)?.Error || null
        },
        getCurrentMonthDateRange(){
            let date = new Date();
            let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
            let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
            return [firstDay,lastDay]
        },
    },
    computed:{
        transferRegion:{
            get:function(){
                return this.transferRegionList.find(s=>s.RegionCode==this.queryData.RegionCode)
            },
            set:function(value){
                this.queryData.RegionCode = value?.RegionCode || null
            }
        },
        sube:{
            get:function(){
                return this.subeList.find(s=>s.BranchCode==this.queryData.BranchCode)
            },
            set:function(value){
                this.queryData.BranchCode = value?.BranchCode || null
            }
        },
        date:{
            get:function(){
                return [this.queryData.StartDate,this.queryData.EndDate].filter(d=>d != null)
            },
            set:function(val){
                this.queryData.StartDate = val[0] || null
                this.queryData.EndDate = val[1] || null
            }
        },
        cari:{
            get:function(){
                return this.cariList.find(s=>s.CARI_KOD==this.queryData.CariCode)
            },
            set:function(value){
                this.queryData.CariCode = value?.CARI_KOD || null
            }
        },
        targetCari:{
            get:function(){
                return this.targetCariList.find(s=>s.CARI_KOD==this.queryData.TargetCariCode)
            },
            set:function(value){
                this.queryData.TargetCariCode = value?.CARI_KOD || null
            }
        },
        stok:{
            get:function(){
                return this.stoks.find(s=>s.Id==this.queryData.Id)
            },
            set:function(value){
                this.queryData.StockCode = value?.Code || null
            }
        }
    }
}
</script>

<style>

</style>